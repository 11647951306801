<template>
  <div class="contacts">
    <v-data-table
      :headers="headers"
      :items="contacts"
      hide-default-footer
      disable-pagination
      :no-data-text="noData"
      v-sortable-data-table
      @sorted="saveOrder"
      class="elevation-1"
      show-expand
      single-expand
    >
      <template v-slot:top>
        <v-toolbar elevation="1" height="280">
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-container>
                <v-spacer></v-spacer>
                <div class="text-center mb-5">
                  <v-btn
                    color="success"
                    rounded
                    v-bind="attrs"
                    v-on="on"
                    :disabled="max_lite || !unit || unit_linked_id != null"
                  >
                    <v-icon>fa-plus</v-icon>
                  </v-btn>
                  <div class="caption">Agregar Residente</div>
                </div>
                <v-spacer></v-spacer>
                <v-select
                  v-model="floor"
                  :items="floors"
                  label="Planta"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  no-data-text="No existen plantas, debe crear una primero."
                  filled
                  dense
                ></v-select>
                <v-select
                  v-model="unit"
                  :items="units"
                  label="Unidad"
                  item-text="name"
                  item-value="id"
                  :color="whiteLabel.colors.primary"
                  :item-color="whiteLabel.colors.primary"
                  no-data-text="Seleccione planta."
                  filled
                  dense
                  @change="unitChanged(unit)"
                ></v-select>
                <v-chip
                  close
                  dark
                  :color="whiteLabel.colors.primary"
                  v-if="unit_mode != null && unit_linked_id == null"
                  :close-icon="
                    unit_mode ? 'mdi-cellphone-wireless' : 'mdi-phone'
                  "
                >
                  Recepción de llamadas vía
                </v-chip>
                <v-chip
                  close
                  dark
                  :color="whiteLabel.colors.primary"
                  v-if="unit_linked_id != null"
                  close-icon="mdi-link"
                >
                  Residentes vinculados con la unidad:
                  {{ unit_linked_floor_name }} - {{ unit_linked_name }}
                </v-chip>
              </v-container>
            </template>

            <v-form ref="form">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nombre"
                          :color="whiteLabel.colors.primary"
                          :rules="requiredRule"
                          @input="inputChanged"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" v-if="license && license.gate">
                        <vue-tel-input-vuetify
                          v-model="editedItem.phone"
                          label="Teléfono"
                          placeholder="Código de área y número"
                          hint="Colocar una coma para indicar extensión."
                          persistent-hint
                          mode="international"
                          :onlyCountries="['AR']"
                          disabledFetchingCountry
                          :color="whiteLabel.colors.primary"
                          :rules="!unit_mode ? requiredRule : []"
                          @input="phoneChanged"
                          :error-messages="phone_error_msg"
                        ></vue-tel-input-vuetify>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                          :color="whiteLabel.colors.primary"
                          :rules="unit_mode ? requiredRule : []"
                          @input="emailChanged(editedItem.email)"
                          :error-messages="email_duplicated_msg"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" v-if="license && license.gate">
                        <v-slider
                          v-model="editedItem.hangup"
                          min="15"
                          max="60"
                          :color="whiteLabel.colors.primary"
                          :track-color="whiteLabel.colors.secondary"
                          hint="Tiempo antes de contestador (en segundos). Evita que el llamado entrante ingrese a su contestador."
                          persistent-hint
                          thumb-label="always"
                          label=""
                          class="mt-3"
                          @end="inputChanged"
                        ></v-slider>
                      </v-col>
                    </v-row>
                    <v-row v-if="license && license.gate && license.doors > 0">
                      <v-col cols="12" sm="6">
                        <div>PIN Ingreso</div>
                        <PincodeInput
                          v-model="pincode"
                          :class="pincodeClasses"
                          :color="whiteLabel.colors.primary"
                          @input="inputChanged"
                        />
                        <div
                          class="error--text v-messages"
                          v-if="pincodeMessage"
                        >
                          {{ pincodeMessage }}
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <div>PIN Antientradera</div>
                        <PincodeInput
                          v-model="pincodeAnti"
                          :class="pincodeAntiClasses"
                          :color="whiteLabel.colors.primary"
                          @input="inputChanged"
                        />
                        <div
                          class="error--text v-messages"
                          v-if="pincodeAntiMessage"
                        >
                          {{ pincodeAntiMessage }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        v-if="license && (license.gate || license.virtual) && license.doors > 0"
                      >
                        <v-checkbox
                          v-model="editedItem.allow_open_door"
                          hint="Seleccione para permitir apertura remota de puerta desde el menú en TappBell Mobile."
                          persistent-hint
                          label="Apertura remota de puerta desde la App"
                          :color="whiteLabel.colors.primary"
                          class="mt-3"
                          @click="inputChanged"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        v-if="license && (license.gate || license.virtual)"
                      >
                        <v-checkbox
                          v-model="editedItem.allow_watch_cameras"
                          hint="Seleccione para permitir visualizar cámaras desde el menú en TappBell Mobile."
                          persistent-hint
                          label="Visualizar cámaras desde la App"
                          :color="whiteLabel.colors.primary"
                          class="mt-3"
                          @click="inputChanged"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :color="whiteLabel.colors.primary"
                    text
                    @click="close"
                    :disabled="loading"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click.prevent="submit"
                    :disabled="!isValid"
                    :loading="loading"
                  >
                    Guardar
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px" persistent>
            <v-card>
              <v-card-title class="headline"
                >Desea eliminar residente "{{ editedItem.name }}"?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="closeDelete"
                  :disabled="loading"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  text
                  @click="deleteItemConfirm"
                  :loading="loading"
                  >Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogQR" max-width="500px">
            <v-card id="QR">
              <v-card-title class="headline"
                >QR para dispositivo de {{ editedItem.name }}</v-card-title
              >
              <v-card-text>
                <p>
                  Escanee este código QR con la app de TappBell para iniciar
                  sesión con...
                </p>
                <p>
                  Planta:
                  {{
                    editedIndex > -1
                      ? floors.find((x) => x.id === floor).name
                      : ""
                  }}<br />
                  Unidad:
                  {{
                    editedIndex > -1
                      ? units.find((x) => x.id === unit).name
                      : ""
                  }}<br />
                  Residente: {{ editedIndex > -1 ? editedItem.name : "" }}
                </p>
              </v-card-text>
              <div
                v-html="editedItem.qr"
                style="max-width: 400px; margin: auto"
              ></div>
              <v-card-actions class="d-print-none">
                <v-spacer></v-spacer>
                <v-btn :color="whiteLabel.colors.primary" text @click="closeQR"
                  >Cerrar</v-btn
                >
                <v-btn color="green darken-1" text @click="printQR"
                  >Imprimir</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDevice" max-width="500px">
            <v-card>
              <v-card-title v-if="editedItem.device" class="headline"
                >Dispositivo vinculado con
                {{
                  editedIndex > -1 ? contacts[editedIndex].name : ""
                }}</v-card-title
              >
              <v-card-title v-if="!editedItem.device" class="headline"
                >Dispositivo no vinculado</v-card-title
              >

              <v-card-text v-if="editedItem.device">
                <div>
                  Marca: {{ editedItem.device.brand }}
                  {{ editedItem.device.device_id }}
                </div>
                <div>
                  Sistema Operativo: {{ editedItem.device.system_name }}
                  {{ editedItem.device.system_version }}
                </div>
                <div>
                  Versión de TappBell Móvil: {{ editedItem.device.version }}
                </div>
                <div class="caption pt-3">
                  <p>
                    Si desea desvincular este dispositivo de la cuenta de
                    usuario, haga click en el botón Desvincular.
                  </p>
                  <p>
                    Este procedimiento es ideal en los casos en que el usuario
                    haya perdido residente con el dispositivo y desee
                    desvincularlo por cuestiones de seguridad.
                  </p>
                </div>
              </v-card-text>
              <v-card-text v-if="!editedItem.device">
                <div>
                  {{ editedIndex > -1 ? contacts[editedIndex].name : "" }} no
                  tiene ningún dispositivo vinculado
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :color="whiteLabel.colors.primary"
                  text
                  @click="closeDevice"
                  :disabled="loading"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="red darken-1"
                  v-if="editedItem.device"
                  text
                  @click="unlinkDeviceConfirm"
                  :loading="loading"
                  >Desvincular</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.dragRow="{}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" dense class="sortHandler">
              mdi-drag-horizontal-variant
            </v-icon></template
          >
          <span>Reordenar</span>
        </v-tooltip>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div v-if="license.video || !license.virtual">
            Teléfono: {{ item.phone }}
          </div>
          <div>Email: {{ item.email }}</div>
          <div v-if="license.video || !license.virtual">
            Colgar luego de {{ item.hangup }} segundos sin atender
          </div>
          <div v-if="item.device">
            Dispositivo conectado: {{ item.device.brand }}
            {{ item.device.device_id }} {{ item.device.system_name }}
            {{ item.device.system_version }}
            <br />
            Versión TappBell en Dispositivo: {{ item.device.version }}
          </div>
          <div v-if="license && (license.gate || license.virtual) && license.doors > 0">
            Apertura remota de puerta desde la App:
            {{ item.allow_open_door ? "Permitido" : "Denegado" }}
          </div>
          <div v-if="license && (license.gate || license.virtual)">
            Visualizar cámaras desde la App:
            {{ item.allow_watch_cameras ? "Permitido" : "Denegado" }}
          </div>
        </td>
      </template>

      <template v-slot:item.actions="{ item }" v-if="unit_linked_id == null">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon></template
          >
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon></template
          >
          <span>Eliminar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="sendEmail(item)"
              v-if="item.email"
            >
              {{ item.email_icon }}
            </v-icon></template
          >
          <span>Reenviar invitación de bienvenida</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              v-if="!item.email"
            >
              mdi-email-off
            </v-icon></template
          >
          <span>Sin E-Mail asociado</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              class="mr-2"
              @click="viewQR(item)"
            >
              mdi-qrcode
            </v-icon></template
          >
          <span>Ver QR</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              @click="unlinkDevice(item)"
              v-if="item.device"
            >
              mdi-cellphone-wireless
            </v-icon>
          </template>
          <span>Dispositivo vinculado</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              dense
              @click="unlinkDevice(item)"
              v-if="!item.device"
            >
              mdi-cellphone-off
            </v-icon></template
          >
          <span>Dispositivo sin vincular</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.visible"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
    <dialog></dialog>
  </div>
</template>

<script>
import { db, ff } from "@/fb";
import { mapGetters } from "vuex";
import Sortable from "sortablejs";
import PincodeInput from "vue-pincode-input";

export default {
  components: {
    PincodeInput,
  },

  data: () => ({
    loading: false,
    floor: null,
    floors: [],
    unit: null,
    unit_mode: null,
    units: [],
    contacts: [],
    snackbar: {
      visible: false,
      timeout: 2000,
      text: "",
      color: "",
    },
    dialog: false,
    dialogDelete: false,
    dialogQR: false,
    dialogDevice: false,
    headers: [
      {
        text: "",
        align: "start",
        sortable: false,
        value: "dragRow",
        width: "1%",
      },
      { text: "Residente", align: "start", sortable: false, value: "name" },
      { text: "Más", value: "data-table-expand", sortable: false },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      id: "",
      phone: "",
      email: "",
      hangup: 0,
      qr: "",
      pincode: "",
      pincodeAnti: "",
      allow_open_door: false,
      allow_watch_cameras: false,
    },
    beforeEditedItem: {
      name: "",
      id: "",
      email: "",
      phone: "",
      hangup: 0,
      qr: "",
      pincode: "",
      pincodeAnti: "",
      allow_open_door: false,
      allow_watch_cameras: false,
    },
    defaultItem: {
      name: "",
      id: null,
      phone: "",
      email: "",
      hangup: 20,
      pincode: "",
      pincodeAnti: "",
      allow_open_door: false,
      allow_watch_cameras: false,
    },
    isValid: false,
    noData: "Seleccione planta y unidad",
    requiredRule: [(v) => !!v || "Campo requerido"],
    emailRule: [(v) => /.+@.+\..+/.test(v) || "Formato de E-mail inválido"],
    email_duplicated_msg: null,
    phone_error_msg: null,
    pincodeClasses: "pincode pincode-empty",
    pincode: "",
    pincodeAntiClasses: "pincode pincode-empty",
    pincodeAnti: "",
    pincodeMessage: "",
    pincodeAntiMessage: "",
    all_pincodes: [],
    unit_linked_id: null,
    unit_linked_name: null,
    unit_linked_floor_id: null,
    unit_linked_floor_name: null,
    unit_linked_mode: null,
    debug: false,
  }),

  props: ["initial_floor", "initial_unit", "initial_unit_mode"],

  mounted() {
    this.getFloors();
    this.floor = this.initial_floor;
    this.unit = this.initial_unit;
    this.unit_mode = this.initial_unit_mode;
  },

  computed: {
    ...mapGetters({
      user: "user",
      license: "license",
      whiteLabel: "whiteLabel",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Residente" : "Editar Residente";
    },
    max_lite() {
      return this.license.lite && this.contacts.length > 0;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    floor() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando unidades ...";
      this.snackbar.color = "success";
      this.getUnits();
    },
    unit() {
      this.snackbar.visible = true;
      this.snackbar.text = "Buscando residentes ...";
      this.snackbar.color = "success";
      if (!this.unit_linked_id) {
        //console.log('is not linked, calling get contacts.');
        this.getContacts();
      } else {
        //console.log('is linked, calling get linked.');
        this.getLinkedUnit();
      }
    },
    pincode() {
      this.editedItem.pincode = this.pincode;
    },
    pincodeAnti() {
      this.editedItem.pincodeAnti = this.pincodeAnti;
    },
  },

  methods: {
    async getFloors() {
      if (this.unit_linked_id) {
        this.contacts = [];
        this.getLinkedUnit();
        this.noData =
          "Los residentes de esta unidad no estan disponibles ya que la unidad seleccionada esta vinculada con otra unidad. Haga click en el boton Residentes vinculados para ver más.";
        return;
      }
      try {
        await db
          .collection("buildings/" + this.user.data.building + "/floors")
          .get()
          .then((floors) => {
            this.floors = [];
            let floor_positions = null;

            db.doc("buildings/" + this.user.data.building)
              .get()
              .then((floor) => {
                floor_positions = floor.data().floor_positions;

                let unsorted_floors = [];
                floors.forEach((floor) => {
                  unsorted_floors.push(floor);
                });

                floor_positions.forEach((floor) => {
                  let f = unsorted_floors.find((f) => f.id === floor);
                  this.floors.push({ name: f.data().name, id: f.id });
                });
              });
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getUnits() {
      try {
        if (this.initial_unit) {
          this.initial_unit = null;
        } else {
          this.unit = null;
          this.unit_mode = null;
        }
        this.unit_linked_id = null;
        await db
          .collection(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              this.floor +
              "/apartments"
          )
          .get()
          .then((units) => {
            this.units = [];
            let unit_positions = null;

            if (!units.empty) {
              db.doc(
                "buildings/" + this.user.data.building + "/floors/" + this.floor
              )
                .get()
                .then((floor) => {
                  unit_positions = floor.data().apartment_positions;
                  let unsorted_units = [];
                  units.forEach((unit) => {
                    unsorted_units.push(unit);
                  });
                  unit_positions.forEach((unit) => {
                    let u = unsorted_units.find((u) => u.id === unit);
                    this.units.push({
                      name: u.data().name,
                      id: u.id,
                      mode: u.data().mode,
                      alias: u.data().alias,
                      remarks: u.data().remarks,
                      linked_id: u.data().linked_id ? u.data().linked_id : null,
                    });
                  });
                });
            } else {
              if (this.unit) {
                this.noData = "No existen unidades para esta planta";
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getContacts(linked_floor = null, linked_unit = null) {
      var floor = linked_floor ? linked_floor : this.floor;
      var unit = linked_unit ? linked_unit : this.unit;
      try {
        await db
          .collection(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              floor +
              "/apartments/" +
              unit +
              "/contacts"
          )
          .get()
          .then((contacts) => {
            this.contacts = [];
            this.all_pincodes = [];
            let contact_positions = null;
            if (!contacts.empty) {
              db.doc(
                "buildings/" +
                  this.user.data.building +
                  "/floors/" +
                  floor +
                  "/apartments/" +
                  unit
              )
                .get()
                .then((unit) => {
                  contact_positions = unit.data().contact_positions;
                  let unsorted_contacts = [];
                  contacts.forEach((contact) => {
                    unsorted_contacts.push(contact);
                  });
                  contact_positions.forEach((contact) => {
                    let c = unsorted_contacts.find((c) => c.id === contact);
                    this.contacts.push({
                      name: c.data().name,
                      id: c.id,
                      phone: c.data().phone,
                      email: c.data().email,
                      email_icon: c.data().email
                        ? "mdi-email"
                        : "mdi-email-off",
                      hangup: c.data().hangup ? c.data().hangup : null,
                      qr: c.data().qr ? c.data().qr.img : null,
                      connectycube: "connectycube" in c.data(),
                      device:
                        "device" in c.data() && c.data().device
                          ? {
                              brand: c.data().device.brand,
                              build_number: c.data().device.build_number,
                              device_id: c.data().device.device_id,
                              system_name: c.data().device.system_name,
                              system_version: c.data().device.system_version,
                              unique_id: c.data().device.unique_id,
                              version: c.data().device.version,
                            }
                          : false,
                      pincode: c.data().code ? c.data().code : "",
                      pincodeAnti: c.data().code_anti ? c.data().code_anti : "",
                      allow_open_door: c.data().allow_open_door
                        ? c.data().allow_open_door
                        : false,
                      allow_watch_cameras: c.data().allow_watch_cameras
                        ? c.data().allow_watch_cameras
                        : false,
                    });
                    this.all_pincodes.push({
                      id: c.id,
                      pincode: c.data().code,
                      pincodeAnti: c.data().code_anti,
                    });
                  });
                });
            } else {
              if (unit) {
                this.noData = "No existen residentes para esta unidad";
              }
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    inputChanged() {
      if (
        this.$refs.form.validate() &&
        !this.phone_error_msg &&
        !this.email_duplicated_msg &&
        this.validatePincodes()
      ) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
      this.consoleDebug("input changed", this.isValid);
    },

    phoneChanged(formattedNumber, { isValid }) {
      this.phone_error_msg = null;
      if (formattedNumber == "" || isValid) {
        this.phone_format_msg = null;
        this.inputChanged();
      } else {
        this.isValid = false;
        this.phone_error_msg = "Formato de teléfono inválido";
      }
    },

    emailChanged(email) {
      this.consoleDebug("email changed");
      this.email_duplicated_msg = null;
      var emailPattern = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (emailPattern.test(email)) {
        this.inputChanged();
      } else {
        this.isValid = false;
        this.email_duplicated_msg = "Formato de E-mail inválido";
      }
    },

    unitChanged(unit) {
      this.unit_mode = this.units.find((x) => x.id === unit).mode;
      this.unit_linked_id = this.units.find((x) => x.id === unit).linked_id;
    },

    editItem(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.beforeEditedItem = Object.assign({}, item);
      this.pincode = item.pincode;
      this.pincodeAnti = item.pincodeAnti;
      this.dialog = true;
      this.isValid = false;
    },

    deleteItem(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    viewQR(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogQR = true;
    },

    printQR() {
      this.$htmlToPaper("QR");
    },

    unlinkDevice(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDevice = true;
    },

    unlinkDeviceConfirm() {
      this.loading = true;
      this.snackbar.visible = true;
      this.snackbar.text = "Desvinculado dispositivo...";
      this.snackbar.color = "success";

      const data = {
        id: this.editedItem.id,
        floor_id: this.floor,
        apartment_id: this.unit,
      };
      ff.httpsCallable("unbindDevice")(data)
        .then((result) => {
          if (!result.data.error) {
            this.contacts[this.editedIndex].device = null;

            this.loading = false;
            this.snackbar.visible = true;
            this.snackbar.text = "Dispositivo desvinculado...";
            this.snackbar.color = "success";
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              result.data.error.code + " - " + result.data.error.message;
            this.snackbar.color = "error";
            console.log(
              "error",
              result.data.error.code + " - " + result.data.error.message
            );
          }
          this.loading = false;
          this.closeDevice();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    deleteItemConfirm() {
      this.loading = true;
      this.snackbar.visible = true;
      this.snackbar.text = "Eliminando residente ...";
      this.snackbar.color = "success";

      const data = {
        id: this.editedItem.id,
        floor_id: this.floor,
        apartment_id: this.unit,
      };
      ff.httpsCallable("deleteContact")(data)
        .then((result) => {
          if (!result.data.error) {
            this.snackbar.visible = true;
            this.snackbar.text = "Residente eliminado ...";
            this.snackbar.color = "success";
            this.loading = false;

            // Make a copy of editedIndex value to avoid undefine errors after removing the contact from the list.
            let aux = this.editedIndex;
            this.editedIndex = -1;
            this.contacts.splice(aux, 1);
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              result.data.error.code + " - " + result.data.error.message;
            this.snackbar.color = "error";
          }
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendEmail(item) {
      this.snackbar.visible = true;
      this.snackbar.text = "Reenviando e-mail de bienvenida...";
      this.snackbar.color = "success";

      const data = {
        id: item.id,
        floor_id: this.floor,
        apartment_id: this.unit,
      };
      var contact = this.contacts.findIndex((x) => x.id == item.id);
      this.contacts[contact].email_icon = "mdi-timer-sand";
      ff.httpsCallable("sendEmailContact")(data)
        .then((result) => {
          if (!result.data.error) {
            this.snackbar.visible = true;
            this.snackbar.text = "E-mail enviado...";
            this.snackbar.color = "success";
            this.contacts[contact].email_icon = "mdi-email-check";
          } else {
            this.snackbar.visible = true;
            this.snackbar.text =
              result.data.error.code + " - " + result.data.error.message;
            this.snackbar.color = "error";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    close() {
      this.dialog = false;
      this.isValid = false;
      this.pincode = "";
      this.pincodeAnti = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeQR() {
      this.dialogQR = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDevice() {
      this.dialogDevice = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async submit() {
      if (this.$refs.form.validate() && this.validatePincodes()) {
        this.snackbar.visible = true;
        this.snackbar.text = "Verificando teléfono y e-mail ...";
        this.snackbar.color = "success";
        this.loading = true;

        let phone = this.editedItem.phone
          .replace(/[ -]/g, "")
          .replace("ext.", ",");
        // Si estoy creando, validar primero con checkEmailAndOrPhone
        if (this.editedIndex == -1) {
          const data = { email: this.editedItem.email, phone: phone };
          ff.httpsCallable("checkEmailAndOrPhone")(data)
            .then((result) => {
              if (result.data.email && result.data.phone) {
                this.save();
              } else {
                if (!result.data.email) {
                  this.email_duplicated_msg =
                    "E-mail existente. Utilice otro diferente.";
                }
                if (!result.data.phone) {
                  this.phone_error_msg =
                    "Teléfono existente. Utilice otro diferente.";
                }
                this.loading = false;
                this.isValid = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // Si estoy modificando, voy directo a grabar.
          this.save();
        }
      }
    },

    save() {
      this.loading = true;
      let contact = {
        name: this.editedItem.name,
        phone: this.editedItem.phone.replace(/[ -]/g, "").replace("ext.", ","),
        email: this.editedItem.email,
        hangup: this.editedItem.hangup ? parseInt(this.editedItem.hangup) : 0,
        code: this.editedItem.pincode ? this.editedItem.pincode : "",
        code_anti: this.editedItem.pincodeAnti
          ? this.editedItem.pincodeAnti
          : "",
        allow_open_door: this.editedItem.allow_open_door
          ? this.editedItem.allow_open_door
          : false,
        allow_watch_cameras: this.editedItem.allow_watch_cameras
          ? this.editedItem.allow_watch_cameras
          : false,
      };
      let beforePhone = this.beforeEditedItem.phone
        .replace(/[ -]/g, "")
        .replace("ext.", ",");
      // Edicion
      if (this.editedIndex > -1) {
        this.snackbar.visible = true;
        this.snackbar.text = "Guardando residente...";
        this.snackbar.color = "success";
        // Si cambia email o telefono, usar el updatecontact cloud function
        if (
          this.beforeEditedItem.email != contact.email ||
          beforePhone != contact.phone
        ) {
          const data = {
            id: this.editedItem.id,
            floor_id: this.floor,
            apartment_id: this.unit,
            email: this.editedItem.email,
            phone: contact.phone,
            name: this.editedItem.name,
          };
          ff.httpsCallable("updateContact")(data)
            .then((result) => {
              if (!result.data.error) {
                // Update fields not updated by the cloud function
                delete contact.phone;
                delete contact.email;
                db.doc(
                  "buildings/" +
                    this.user.data.building +
                    "/floors/" +
                    this.floor +
                    "/apartments/" +
                    this.unit +
                    "/contacts/" +
                    this.editedItem.id
                )
                  .update(contact)
                  .then(() => {
                    this.loading = false;
                    contact.id = result.data.user.id;
                    contact.qr = result.data.user.qr;
                    contact.phone = this.editedItem.phone;
                    contact.email = this.editedItem.email;
                    contact.pincode = contact.code;
                    contact.pincodeAnti = contact.code_anti;
                    contact.allow_open_door = this.editedItem.allow_open_door;
                    contact.allow_watch_cameras = this.editedItem.allow_watch_cameras;
                    delete contact.code;
                    delete contact.code_anti;
                    Object.assign(this.contacts[this.editedIndex], contact);

                    // Update new pincodes into all_pincodes array.
                    this.all_pincodes = this.all_pincodes.map((obj) => {
                      if (obj.id === this.editedItem.id) {
                        return {
                          ...obj,
                          pincode: this.editedItem.pincode,
                          pincodeAnti: this.editedItem.pincodeAnti,
                        };
                      }
                      return obj;
                    });

                    this.snackbar.visible = true;
                    this.snackbar.text = "Residente guardado...";
                    this.snackbar.color = "success";
                    this.loading = false;
                    this.close();
                  })
                  .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.close();
                  });
              } else {
                // Invalid phone
                if (result.data.error.code == "103") {
                  this.snackbar.text =
                    "El teléfono no es válido o ya existe para otro usuario...";
                  this.phone_error_msg =
                    "Teléfono existente. Utilice otro diferente.";
                }
                // Invalid email
                if (result.data.error.code == "104") {
                  this.snackbar.text =
                    "El e-mail no es válido o ya existe para otro usuario...";
                  this.email_duplicated_msg =
                    "E-mail existente. Utilice otro diferente.";
                }
                // Invalid phone & email
                if (result.data.error.code == "105") {
                  this.snackbar.text =
                    "El teléfono y el e-mail no son válidos o ya existen para otro usuario...";
                  this.phone_error_msg =
                    "Teléfono existente. Utilice otro diferente.";
                  this.email_duplicated_msg =
                    "E-mail existente. Utilice otro diferente.";
                }
                this.snackbar.visible = true;
                this.snackbar.color = "error";
                this.loading = false;
                console.log(
                  "update contact error",
                  result.data.error.code + " - " + result.data.error.message
                );
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.close();
            });
        } else {
          // Sino, modificar directo en firestore
          this.snackbar.visible = true;
          this.snackbar.text = "Cambios guardados...";
          this.snackbar.color = "success";
          let id = this.editedItem.id;
          db.doc(
            "buildings/" +
              this.user.data.building +
              "/floors/" +
              this.floor +
              "/apartments/" +
              this.unit +
              "/contacts/" +
              id
          )
            .update(contact)
            .then(() => {
              Object.assign(this.contacts[this.editedIndex], this.editedItem);

              // Update new pincodes into all_pincodes array.
              this.all_pincodes = this.all_pincodes.map((obj) => {
                if (obj.id === id) {
                  return {
                    ...obj,
                    pincode: this.editedItem.pincode,
                    pincodeAnti: this.editedItem.pincodeAnti,
                  };
                }
                return obj;
              });

              this.loading = false;
              this.close();
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        }
      } else {
        // Creacion
        this.loading = true;
        this.snackbar.visible = true;
        this.snackbar.text = "Creando residente ...";
        this.snackbar.color = "success";
        contact.build_id = this.user.data.building;
        contact.apartment_id = this.unit;

        const data = { user: contact, floor_id: this.floor };
        ff.httpsCallable("createContact")(data)
          .then((result) => {
            if (!result.data.error) {
              this.loading = false;
              contact.id = result.data.user.id;
              contact.qr = result.data.user.qr.img;
              contact.email_icon = contact.email
                ? "mdi-email"
                : "mdi-email-off";
              contact.pincode = result.data.user.code;
              contact.pincodeAnti = result.data.user.code_anti;
              contact.allow_open_door = result.data.user.allow_open_door;
              contact.allow_watch_cameras =
                result.data.user.allow_watch_cameras;
              delete contact.build_id;
              delete contact.floor_id;
              this.contacts.push(contact);

              // Insert new pincodes into all_pincodes array.
              this.all_pincodes.push({
                id: contact.id,
                pincode: result.data.user.code,
                pincodeAnti: result.data.user.code_anti,
              });

              this.snackbar.visible = true;
              this.snackbar.text = "Residente creado...";
              this.snackbar.color = "success";
            } else {
              this.snackbar.visible = true;
              this.snackbar.text =
                result.data.error.code + " - " + result.data.error.message;
              this.snackbar.color = "error";
              console.log(
                "error",
                result.data.error.code + " - " + result.data.error.message
              );
            }
            this.loading = false;
            this.close();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },

    saveOrder(event) {
      if (!this.unit_linked_id) {
        this.snackbar.visible = true;
        this.snackbar.text = "Orden de residentes actualizado ...";
        this.snackbar.color = "success";
        const movedItem = this.contacts.splice(event.oldIndex, 1)[0];
        this.contacts.splice(event.newIndex, 0, movedItem);
        const new_positions = [];
        this.contacts.forEach((contact) => {
          new_positions.push(contact.id);
        });
        db.doc(
          "buildings/" +
            this.user.data.building +
            "/floors/" +
            this.floor +
            "/apartments/" +
            this.unit
        )
          .update({ contact_positions: new_positions })
          .then(() => {
            //
          });
      }
    },

    validatePincodes() {
      this.pincodeMessage = "";
      this.pincodeAntiMessage = "";
      this.pincodeClasses = "pincode pincode-success";
      this.pincodeAntiClasses = "pincode pincode-success";
      let pass = true;
      if (this.pincode.length < 4) {
        this.pincodeMessage = "El PIN debe tener 4 dígitos";
        this.pincodeClasses = "pincode pincode-error";
        pass = false;
      }
      if (this.pincodeAnti.length < 4) {
        this.pincodeAntiMessage = "El PIN debe tener 4 dígitos";
        this.pincodeAntiClasses = "pincode pincode-error";
        pass = false;
      }
      if (
        this.pincode.length == 4 &&
        this.pincodeAnti.length == 4 &&
        this.pincodeAnti == this.pincode
      ) {
        this.pincodeAntiMessage =
          "El PIN Antientradera no puede ser igual al PIN Ingreso";
        this.pincodeAntiClasses = "pincode pincode-error";
        pass = false;
      }

      this.all_pincodes.forEach((pincodes) => {
        if (
          pincodes.id != this.editedItem.id &&
          (this.pincode == pincodes.pincode ||
            this.pincode == pincodes.pincodeAnti)
        ) {
          this.pincodeMessage =
            "PIN Ingreso duplicado para la unidad, ingrese otro";
          this.pincodeClasses = "pincode pincode-error";
          pass = false;
          //break;
        }
        if (
          pincodes.id != this.editedItem.id &&
          (this.pincodeAnti == pincodes.pincode ||
            this.pincodeAnti == pincodes.pincodeAnti)
        ) {
          this.pincodeAntiMessage =
            "PIN Antientradera duplicado para la unidad, ingrese otro";
          this.pincodeAntiClasses = "pincode pincode-error";
          pass = false;
          //break;
        }
      });

      if (this.pincode.length == 0 && this.pincodeAnti.length == 0) {
        this.pincodeMessage = "";
        this.pincodeAntiMessage = "";
        this.pincodeClasses = "pincode pincode-empty";
        this.pincodeAntiClasses = "pincode pincode-empty";
        this.pincode = "";
        this.pincodeAnti = "";
        pass = true;
      }

      this.consoleDebug("validatePincodes", pass);
      return pass;
    },

    consoleDebug(label = "", value = "") {
      if (this.debug) {
        console.log("---", label, value, "---");
      }
    },

    async getLinkedUnit() {
      try {
        await db
          .collectionGroup("apartments")
          .where("build_id", "==", this.user.data.building)
          .get()
          .then((units) => {
            units.forEach((unit) => {
              if (unit.id == this.unit_linked_id) {
                this.unit_linked_name = unit.data().name;
                this.unit_linked_floor_id = unit.data().floor_id;
                this.unit_linked_mode = unit.data().mode;
                db.doc(
                  "buildings/" +
                    this.user.data.building +
                    "/floors/" +
                    this.unit_linked_floor_id
                )
                  .get()
                  .then((floor) => {
                    this.unit_linked_floor_name = floor.data().name;
                    this.getContacts(
                      this.unit_linked_floor_id,
                      this.unit_linked_id
                    );
                  });
              }
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          handle: ".sortHandler",
          onUpdate: function (event) {
            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.sortHandler {
  &:hover {
    cursor: ns-resize;
  }
}

.v-data-table__expanded {
  background-color: #e9e9e9 !important;
}
.v-data-table__expanded__row {
  background-color: red;
}

.v-data-table__expanded__content {
  box-shadow: unset;
}
</style>

<style lang="scss">
.pincode {
  .vue-pincode-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 7px 0 0;
    font-size: 20px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0;

    &:focus {
      box-shadow: 0 0 5px black;
    }
  }

  &.pincode-error {
    .vue-pincode-input {
      border: 2px solid red;
    }
  }

  &.pincode-success {
    .vue-pincode-input {
      border: 2px solid yellowgreen;
    }
  }

  &.pincode-empty {
    .vue-pincode-input {
      border: 2px solid rgb(0, 153, 255);
    }
  }
}
</style>
